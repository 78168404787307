<template>
	<v-layout v-if="givingFormLoaded" wrap>
		<v-flex xs12 sm12 md10 offset-md1 lg6 offset-lg3 xl6 offset-xl3 :mt-6="!showNav">
			<DonorNav v-if="showNav"></DonorNav>
			<r-card :color="cardBackgroundColor" :class="{ 'content-hidden': hideFormContent }">
				<FormHeader slot="media"></FormHeader>
				<slot></slot>
			</r-card>
		</v-flex>
		<v-flex xs12 sm12 md10 offset-md1 lg6 offset-lg3 xl6 offset-xl3>
			<DonorFooter v-if="transactionState.transaction.integrationType !== 5"></DonorFooter>
		</v-flex>
	</v-layout>
</template>

<script>
import DonorNav from '@/components/giving/sharedForms/DonorNav.vue';
import FormHeader from '@/components/giving/donorForm/FormHeader.vue';
import DonorFooter from '@/components/giving/sharedForms/DonorFooter.vue';
import variables from '@/scss/variables.scss';
import moment from 'moment';
import _isNumber from 'lodash/isNumber';
import { formatDollarsToCents } from '@/helpers/formatters';

export default {
	components: {
		DonorNav,
		FormHeader,
		DonorFooter
	},
	data() {
		return {
			givingFormLoaded: false,
			givingFormState: _REALM.STORE.givingFormStore.state,
			transactionState: _REALM.STORE.transactionStore.state,
			layoutState: _REALM.STORE.layoutStore.state,
			campaignState: _REALM.STORE.campaignStore.state
		};
	},
	computed: {
		showNav() {
			return this.transactionState.transaction.integrationType !== 5 && !this.isEngagement && _REALM.STORE.givingFormStore.isAuthEnabled() && !_REALM.VM.$route.query.recurrenceId;
		},
		cardBackgroundColor() {
			return this.layoutState.darkTheme ? '' : 'default';
		},
		isEngagement() {
			return _REALM.VM.$route.query.isEngagement && _REALM.VM.$route.query.isEngagement.toLowerCase() === 'true';
		},
		hideFormContent() {
			// Hide the form content when it's empty (i.e., only show the header). This happens on the Thank You page if there are no further actions.
			return this.givingFormState.currentDonorPage === 'ThankYou' && !_REALM.STORE.givingFormStore.canCreateAccount() && !_REALM.STORE.givingFormStore.canCreateRecurrence() && !_REALM.STORE.givingFormStore.canCreatePledge(this.campaignState.availableCampaigns);
		}
	},
	async created() {
		var self = this;
		_REALM.STORE.transactionStore.resetTransaction();
		await self.setDefaultsFromQueryString();
		self.textSetup().then(function() {
			self.getGivingForm();
		});
	},
	methods: {
		async setDefaultsFromQueryString() {
			this.setTransactionAccount();
			_REALM.STORE.transactionStore.state.transaction.amountsList[0].amount = formatDollarsToCents(_REALM.VM.$route.query.amount);
			if (_REALM.VM.$route.query.fundId) {
				_REALM.STORE.transactionStore.state.transaction.amountsList[0].fundId = _REALM.VM.$route.query.fundId.toLowerCase();
			}

			if (_REALM.VM.$route.query.pledgeData) {
				var pledgeData = JSON.parse(_REALM.VM.$route.query.pledgeData);
				_REALM.STORE.transactionStore.state.transaction.pledgeFundId = _REALM.VM.$route.query.fundId;
				_REALM.STORE.transactionStore.state.transaction.frequencyType = this.convertFrequencyType(pledgeData.frequency);
				_REALM.STORE.transactionStore.state.transaction.giftDate = moment(pledgeData.beginDate);
				_REALM.STORE.transactionStore.state.transaction.stopDate = moment(pledgeData.endDate);
				_REALM.STORE.transactionStore.state.transaction.completionType = _REALM.STORE.transactionStore.enums.mint_Recurrence_CompletionType.STOP_DATE;
				_REALM.STORE.transactionStore.state.transaction.donorSentAccountInvite = _REALM.VM.$route.query.donorSentAccountInvite == 'true' ? true : false;
				_REALM.STORE.transactionStore.state.transaction.isNewProfile = pledgeData.isNewProfile === true ? true : false;

				if (!_REALM.STORE.accountStore.isLoggedIn()) {
					_REALM.STORE.givingFormStore.state.transactionAccount.primaryEmail = _REALM.VM.$route.query.email;
					_REALM.STORE.givingFormStore.state.transactionAccount.individualId = _REALM.VM.$route.query.individualId;
					_REALM.STORE.givingFormStore.state.transactionAccount.mintCustomerId = _REALM.VM.$route.query.mintCustomerId;
				}
			}

			// Text giving also uses a 'token' param in the query, so we need to watch for that.
			if (_REALM.VM.$route.query.token && _REALM.STORE.layoutStore.isPaymentForm() && !_REALM.STORE.givingFormStore.isTextGiver()) {
				const tokenGivingFormData = await _REALM.STORE.transactionStore.readPaymentToken(_REALM.VM.$route.query.token);
				_REALM.STORE.transactionStore.loadGivingFormQueryData(tokenGivingFormData);
			}

			if (_REALM.VM.$route.query.givingFormData) {
				var givingFormData = JSON.parse(decodeURIComponent(_REALM.VM.$route.query.givingFormData));
				_REALM.STORE.transactionStore.loadGivingFormQueryData(givingFormData);
			}

			if (_REALM.VM.$route.query.importData) {
				var importData = JSON.parse(_REALM.VM.$route.query.importData);
				_REALM.STORE.transactionStore.state.transaction.amountsList = importData.amounts;
				_REALM.STORE.transactionStore.state.transaction.frequencyType = importData.frequency;
				_REALM.STORE.transactionStore.state.transaction.giftDate = moment(importData.beginDate);
				if (importData.endDate) {
					_REALM.STORE.transactionStore.state.transaction.stopDate = moment(importData.endDate);
					_REALM.STORE.transactionStore.state.transaction.completionType = _REALM.STORE.transactionStore.enums.mint_Recurrence_CompletionType.STOP_DATE;
				} else {
					_REALM.STORE.transactionStore.state.transaction.completionType = _REALM.STORE.transactionStore.enums.mint_Recurrence_CompletionType.UNTIL_I_CANCEL;
				}

				if (importData.firstDayOfMonth) {
					_REALM.STORE.transactionStore.state.transaction.firstDayOfMonth = importData.firstDayOfMonth;
				}

				if (importData.secondDayOfMonth) {
					_REALM.STORE.transactionStore.state.transaction.secondDayOfMonth = importData.secondDayOfMonth;
				}

				_REALM.STORE.givingFormStore.state.transactionAccount.individualId = _REALM.VM.$route.query.individualId;
				_REALM.STORE.givingFormStore.state.transactionAccount.mintCustomerId = _REALM.VM.$route.query.mintCustomerId;
			}

			if (_REALM.VM.$route.query.sourceId) {
				_REALM.STORE.transactionStore.state.transaction.sourceId = _REALM.VM.$route.query.sourceId;
			}

			this.settingIntegrationType();
		},
		settingIntegrationType() {
			var integrationType = _REALM.STORE.transactionStore.enums.IntegrationType.WEBSITE;
			if (_REALM.VM.$route.query.integrationType) {
				integrationType = _REALM.VM.$route.query.integrationType;
				if (!_isNumber(integrationType)) {
					if (!isNaN(parseInt(integrationType, 10))) {
						integrationType = parseInt(integrationType, 10);
					}

					if (!_isNumber(integrationType)) {
						var value = _REALM.STORE.transactionStore.enums.IntegrationType[integrationType];
						if (value) {
							integrationType = value;
						} else {
							integrationType = _REALM.STORE.transactionStore.enums.IntegrationType.WEBSITE;
						}
					}
				}
			}

			_REALM.STORE.transactionStore.state.transaction.integrationType = integrationType;
		},
		setTransactionAccount() {
			if (_REALM.STORE.accountStore.isLoggedIn()) {
				const individualId = _REALM.VM.$route.query.individualId || _REALM.STORE.accountStore.state.authAccount.individualId;
				_REALM.STORE.givingFormStore.setTransactionAccount(individualId);
			}
		},
		textSetup() {
			var self = this;
			return new Promise(function(resolve) {
				if (!_REALM.STORE.givingFormStore.isTextGiver() || _REALM.STORE.layoutStore.isPaymentForm()) {
					return resolve();
				}

				// get mint customerid from token
				return _REALM.STORE.givingFormStore
					.getMintCustomerFromToken(_REALM.VM.$route.query.token)
					.then(function(data) {
						_REALM.STORE.accountStore.clearLogin();
						_REALM.STORE.givingFormStore.resetTransactionAccount();
						self.givingFormState.transactionAccount = {
							mintCustomerId: data.mintCustomerId,
							phoneNumber: data.phoneNumber,
							label: data.label,
							primaryEmail: data.primaryEmail
						};

						self.transactionState.transaction.textToken = _REALM.VM.$route.query.token;
						_REALM.STORE.givingFormStore.setTransactionHasEmail();
						return resolve();
					})
					.catch(function() {
						self.givingFormState.textTokenExpired = true;
						return resolve();
					});
			});
		},
		setGivingFormLoadedValues() {
			this.$vuetify.theme.themes.light.primary = this.givingFormState.givingForm.useDarkBodyScheme ? variables['color-white'] : variables['color-black'];
			this.$vuetify.theme.themes.light.secondary = this.givingFormState.givingForm.buttonColor;
			this.$vuetify.theme.themes.light.accent = this.givingFormState.givingForm.buttonColor;
			this.$vuetify.theme.themes.light.info = this.givingFormState.givingForm.buttonColor;
			this.$vuetify.theme.themes.light.error = this.givingFormState.givingForm.useDarkBodyScheme ? variables['color-error-dark-theme'] : variables['color-error'];

			this.$vuetify.theme.themes.dark.primary = this.givingFormState.givingForm.useDarkBodyScheme ? variables['color-white'] : variables['color-black'];
			this.$vuetify.theme.themes.dark.secondary = this.givingFormState.givingForm.buttonColor;
			this.$vuetify.theme.themes.dark.accent = this.givingFormState.givingForm.buttonColor;
			this.$vuetify.theme.themes.dark.info = this.givingFormState.givingForm.buttonColor;
			this.$vuetify.theme.themes.dark.error = this.givingFormState.givingForm.useDarkBodyScheme ? variables['color-error-dark-theme'] : variables['color-error'];

			_REALM.STORE.layoutStore.setDarkTheme(this.givingFormState.givingForm.useDarkBodyScheme);
			if (!_REALM.VM.$route.query.amount && !_REALM.STORE.transactionStore.state.transaction.amountsList[0].amount) {
				_REALM.STORE.transactionStore.state.transaction.amountsList[0].amount = _REALM.STORE.givingFormStore.state.givingForm.defaultAmount;
			}

			this.givingFormLoaded = true;
		},
		async getGivingForm() {
			var self = this;

			const promises = [_REALM.STORE.fundStore.listOnline({ filterCampusFunds: false }), _REALM.STORE.memorialStore.listActive()];

			if (_REALM.STORE.transactionStore.isEditingRecurrence()) {
				promises.push(_REALM.STORE.memorialStore.listInactive());
			}

			await Promise.all(promises);

			if (_REALM.VM.$route.params.givingFormUrl) {
				_REALM.STORE.givingFormStore
					.getGivingFormByUrl(_REALM.VM.$route.params.givingFormUrl)
					.then(self.setGivingFormLoadedValues)
					.catch(function() {
						self.redirectDefaultGivingForm();
					});
			} else {
				self.redirectDefaultGivingForm();
			}
		},
		redirectDefaultGivingForm() {
			_REALM.STORE.givingFormStore.getDefaultGivingForm().then(function(givingForm) {
				_REALM.VM.$router.replace({
					name: _REALM.VM.$route.name,
					params: {
						givingFormUrl: givingForm.urlPath
					},
					query: _REALM.VM.$route.query
				});
			});
		},
		convertFrequencyType(frequency) {
			switch (frequency) {
				case 'AsCan':
					frequency = _REALM.STORE.transactionStore.enums.mint_Recurrence_FrequencyType.ONCE;
					break;
				case 'Weekly':
					frequency = _REALM.STORE.transactionStore.enums.mint_Recurrence_FrequencyType.WEEKLY;
					break;
				case 'BiWeekly':
					frequency = _REALM.STORE.transactionStore.enums.mint_Recurrence_FrequencyType.EVERY_OTHER_WEEK;
					break;
				case 'Monthly':
					frequency = _REALM.STORE.transactionStore.enums.mint_Recurrence_FrequencyType.MONTHLY;
					break;
				case 'SemiMonthly':
					frequency = _REALM.STORE.transactionStore.enums.mint_Recurrence_FrequencyType.TWICE_A_MONTH;
					break;
				case 'Quarterly':
					frequency = _REALM.STORE.transactionStore.enums.mint_Recurrence_FrequencyType.EVERY_THREE_MONTHS;
					break;
				default:
					frequency = _REALM.STORE.transactionStore.enums.mint_Recurrence_FrequencyType.ONCE;
			}
			return frequency;
		}
	}
};
</script>
