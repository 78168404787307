<template>
	<DonorMainContent :key="realmVm.$route.fullPath">
		<v-slide-y-transition appear mode="out-in">
			<DonorForm></DonorForm>
		</v-slide-y-transition>
	</DonorMainContent>
</template>

<script>
import DonorMainContent from '@/components/giving/donorForm/DonorMainContent.vue';
import DonorForm from '@/components/giving/donorForm/DonorForm.vue';

export default {
	components: {
		DonorForm,
		DonorMainContent
	},
	data() {
		return {
			realmVm: _REALM.VM
		};
	}
};
</script>
